@import '~quill/dist/quill.snow.css';


.app__wrapper {
  display: flex;
  flex-direction: column;
  height: 97vh;
  width: 97vw;

  .app__header {
    height: 7vh;
  }

  .app__container {
    flex: 1 1 auto;
    //background-color: #1a1a1a;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 91vh;

    .app__side-menu {
      flex: 0 0 200px;
      display: flex;
    }

    .app__content {
      //padding: 1rem;
      flex: 1 1 auto;
      display: flex;
    }
  }
}

.container {
  display: flex;
  width: 55vw;
  justify-content: space-between;
}

.helpButton {
  padding-right: 1rem;
}

.menuFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.username {
  padding-right: 1rem;
}

.dropDownRight {
  text-align: right;
}

.text-no-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100%;
}

.ant-checkbox-inner {
  border-radius: 100% !important;
}

.buttonWithImage {
  font-size: 16px;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* justify-content: center; */
  align-items: flex-start;
  align-content: flex-start;
  flex: 1 1;
}

.cardContainer::-webkit-scrollbar {
  width: 3px;
}

.cardContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px black;
  border-radius: 10px;
}

.cardContainer::-webkit-scrollbar-thumb {
  border-radius: 40px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.31);
}

.grayscaleAvatar {
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
}