.listHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  i {
    color: #a61d24;
  }
}

.contactPersonList {
  display: flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.orgaName {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.contactPersonDiv {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.ant-upload-list-item {
  border-radius: 100% !important;
  overflow: hidden;
}
