.organization-navigation {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .organization-list {
    height: 100%;
    overflow-y: auto;
  }

  .organization-button {
    padding: 0.5rem 1.5rem;

    .inner {
      border: 0.2rem solid black;
      border-radius: 0.5rem;
      overflow: hidden;

      .notActive {
        opacity: 0.5;
      }
    }

    .inner.active {
      border-color: white;
    }

    .inner:hover {
      border: 0.2rem solid white;
      border-radius: 0.5rem;
      cursor: pointer;

      .notActive {
        opacity: 1;
      }
    }
  }
}

.organization-navigation-divider {
  height: 100%;
  background-color: #1d1d1d;
}
