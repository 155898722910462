.app__organizationUserPage {
  width: 100%;
  padding: -1rem;

  .inviteButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }

  .vertical_arrangment  {
    display: flex;
    flex-direction: column;
    background-color: 'green';
    height: 100%;
  }

  .header {
    height: 7rem;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    align-items: flex-start;
    position: relative;
  }

  .header .backgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.4;
    z-index: 0;
    filter: blur(10px);
  }

  .organizationInfo {
    padding: 0rem 1rem 0rem 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
  }

  .organizationInfo .details {
    padding-left: 1rem;
  }

  .organizationInfo .details .buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }

  .organizationData {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 1rem 1rem;
  }

  .organizationData .dataSection {
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
  }

  .organizationData .dataSection .dataContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .organizationData .dataSection .dataContainer.teams {
    padding-top: 1rem;
    padding-left: 1rem;
  }

  .organizationData .dataSection .title {
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  .organizationData .dataSection .dataContainer .card {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .organizationData .dataSection .dataContainer .card .center {
    display: flex;
  }

  .organizationData .dataSection .dataContainer .card .more {
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
