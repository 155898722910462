.users-tab-content {
  padding-left: 2vw;
  padding-right: 2vw;

  .users-bulk-upload {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}

.bulk-upload-file {
  width: 300px !important;
  margin: 20px auto;
}
